import moment from 'moment';
export const RoleTypes = {
  Manager: 'manager',
  Employee: 'employee',
  Admin: 'admin',
};

export const LeadStatus = {
  Lead: 'lead',
  Opportunity: 'opportunity',
  NotInterested: 'notInterested',
  PROPOSAL: 'proposal',
  SALE: 'sale',
  WIN: 'win',
  LOSS: 'losses',
  DELETED: 'deleted',
  PENDING: 'pending',
  UpdateSale: 'updateSale',
  Action: 'actions',
  Job: 'job',
};

export const noteStatus = {
  Opportunity: 'Opportunity',
  PROPOSAL: 'Proposal',
  SALE: 'Sales',
};
export const historyType = {
  Opportunity: 'Opportunity',
  PROPOSAL: 'Proposal',
  SALE: 'Sales',
  Task: 'task',
  Call: 'call',
  Meeting: 'meeting',
  Deposit: 'Deposit',
  ScheduleJob: 'ScheduleJob',
};

export const timeMHD = [
  { value: 'Minutes', label: 'Minutes' },
  { value: 'Hours', label: 'Hours' },
];

export const defualtOption = {
  chart: {
    height: 170,
    type: 'area',
    toolbar: {
      show: false,
    },
    dropShadow: {
      enabled: true,
      top: 5,
      left: 0,
      blur: 4,
      color: ['#F8EDE3', '#3DED97'],
      opacity: 0.3,
    },
  },
  stroke: {
    curve: 'smooth',
    width: 3,
    colors: ['#F8EDE3', '#3DED97'],
  },
  grid: {
    show: false,
  },
  fill: {
    type: ['gradient', 'gradient'],
    gradient: {
      type: 'vertical',
      colorStops: [
        [
          {
            offset: 0,
            color: '#F8EDE3',
            opacity: 0.1,
          },
          {
            offset: 70,
            color: '#FFFFFFF',
            opacity: 0.03,
          },
        ],
        [
          {
            offset: 0,
            color: '#3DED97', //'rgba(55, 144, 216, 0.31)', //#72b1aa00
            opacity: 1,
          },
          {
            offset: 100,
            color: '#3DED97',
            opacity: 1,
          },
        ],
      ],
    },
  },
  tooltip: {
    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
      return (
        `<div class="total_sale_tooltip ${seriesIndex === 1 ? 'green-border' : ''}">` +
        '<span class="dt_value">' +
        `${w.globals.categoryLabels[dataPointIndex]} ${moment().format('YYYY')}` +
        '</span>' +
        '<span class="price_value">' +
        `$${series[seriesIndex][dataPointIndex]?.toLocaleString()}` +
        '</span>' +
        '</div>'
      );
    },
    fixed: {
      enabled: false,
      position: 'topCenter',
      offsetX: 200,
      offsetY: 0,
    },
  },
  dataLabels: {
    enabled: false,
  },
  xaxis: {
    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    position: 'bottom',
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    crosshairs: {
      show: false,
    },
    labels: {
      style: {
        colors: [
          '#ffffff',
          '#ffffff',
          '#ffffff',
          '#ffffff',
          '#ffffff',
          '#ffffff',
          '#ffffff',
          '#ffffff',
          '#ffffff',
          '#ffffff',
          '#ffffff',
          '#ffffff',
        ],
        fontSize: '8px',
        cssClass: 'stp_chart_X_label',
      },
      offsetY: -5,
    },
  },
  yaxis: {
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    labels: {
      show: false,
      formatter: function (val) {
        return val + '%';
      },
    },
  },
  markers: {
    size: 0,
    colors: ['#3790d8'],
    strokeColor: '#63b5b7',
    strokeWidth: 3,
    gradient: {
      gradientToColors: ['#FDD835'],
    },
    hover: {
      size: 8,
    },
  },
};

export const defualtOption2 = {
  chart: {
    height: 170,
    type: 'area',
    toolbar: {
      show: false,
    },
    dropShadow: {
      enabled: true,
      top: 5,
      left: 0,
      blur: 4,
      color: ['#29d7df', '#276cbd'],
      opacity: 0.3,
    },
  },
  stroke: {
    curve: 'smooth',
    width: 3,
    colors: ['#29d7df', '#276cbd'],
  },
  grid: {
    show: false,
  },
  fill: {
    type: ['gradient', 'gradient'],
    gradient: {
      type: 'linear',
      colorStops: [
        [
          {
            offset: 0,
            color: '#29d7df',
            opacity: 0.1,
          },
          {
            offset: 100,
            color: '#29d7df',
            opacity: 0,
          },
        ],
        [
          {
            offset: 0,
            color: '#276cbd', //'rgba(55, 144, 216, 0.31)', //#72b1aa00
            opacity: 1,
          },
          {
            offset: 100,
            color: '#276cbd',
            opacity: 1,
          },
        ],
      ],
    },
  },
  tooltip: {
    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
      return (
        '<div class="total_sale_tooltip">' +
        '<span class="dt_value">' +
        `${w.globals.categoryLabels[dataPointIndex]} ${moment().format('YYYY')}` +
        '</span>' +
        '<span class="price_value">' +
        `$${series[seriesIndex][dataPointIndex]?.toLocaleString()}` +
        '</span>' +
        '</div>'
      );
    },
    fixed: {
      enabled: false,
      position: 'topCenter',
      offsetX: 200,
      offsetY: 0,
    },
  },
  dataLabels: {
    enabled: false,
  },
  xaxis: {
    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    position: 'bottom',
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    crosshairs: {
      show: false,
    },
    labels: {
      style: {
        colors: [
          '#939EA3',
          '#939EA3',
          '#939EA3',
          '#939EA3',
          '#939EA3',
          '#939EA3',
          '#939EA3',
          '#939EA3',
          '#939EA3',
          '#939EA3',
          '#939EA3',
          '#939EA3',
        ],
        fontSize: '8px',
        cssClass: 'stp_chart_X_label',
      },
      offsetY: -5,
    },
  },
  yaxis: {
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    labels: {
      show: false,
      formatter: function (val) {
        return val + '%';
      },
    },
  },
  markers: {
    size: 0,
    colors: ['#3790d8'],
    strokeColor: '#63b5b7',
    strokeWidth: 3,
    gradient: {
      gradientToColors: ['#FDD835'],
    },
    hover: {
      size: 8,
    },
  },
};

export const createUpper = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const DurationOption = [
  {
    value: '15M',
    label: '15 Minutes',
  },
  {
    value: '30M',
    label: '30 Minutes',
  },
  {
    value: '45M',
    label: '45 Minutes',
  },
  {
    value: '1H',
    label: '1 Hour',
  },
  {
    value: '2H',
    label: '2 Hours',
  },
];

export const ActionType = {
  Task: 'task',
  Call: 'call',
  Meeting: 'meeting',
};

export const commissionTypeEnum = {
  FixedRate: 'fixed',
  Tiered: 'tiered',
  MARGIN: 'margin',
};

export const checkColorRange = {
  Task: 'task',
  Call: 'call',
  Meeting: 'meeting',
};

export const customStyles = {
  menuList: (provided) => ({
    ...provided,
    color: '#2775bd',
    maxHeight: '100px',
  }),
  input: (provided) => ({
    ...provided,
    color: '#2775bd',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#ffffff' : '#2775bd',
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#ffffff' : '#2775bd',
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    color: '#2775bd',
  }),
  placeholder: (provided) => {
    return {
      ...provided,
      color: '#da3b01',
    };
  },
  dropdownIndicator: (base) => ({
    ...base,
    color: '#59626B',
    padding: '4px',
  }),
};

export const customStylesAction = {
  menuList: (provided) => ({
    ...provided,
    color: '#2775bd',
  }),
  input: (provided) => ({
    ...provided,
    color: '#2775bd',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#ffffff' : '#2775bd',
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#ffffff' : '#2775bd',
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    color: '#2775bd',
  }),
  placeholder: (provided) => {
    return {
      ...provided,
      color: '#2775bd',
    };
  },
};

export const customStylesDeal = {
  menuList: (provided) => ({
    ...provided,
    color: '#222B2E;',
  }),
  input: (provided) => ({
    ...provided,
    color: '#222B2E;',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#ffffff' : '#222B2E;',
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#ffffff' : '#222B2E;',
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    color: '#222B2E;',
  }),
  placeholder: (provided) => {
    return {
      ...provided,
      color: '#222B2E;',
    };
  },
};

export const scheduleCallInitialValues = {
  subject: '',
  callTo: '',
  phone: '',
  startDate: '',
  startTime: '',
  duration: '',
  notes: '',
  reminder: [],
  reminderDate: '',
  reminderTime: '',
};
export const scheduleMeetInitialValues = {
  subject: '',
  location: '',
  startDate: '',
  startTime: '',
  duration: '',
  notes: '',
  reminder: [],
  reminderDate: '',
  reminderTime: '',
};

export const createTaskInitialValues = {
  subject: '',
  startDate: '',
  startTime: '',
  notes: '',
  reminder: [],
  reminderDate: '',
  reminderTime: '',
};

export const actionType = {
  Task: 'task',
  Call: 'call',
  Meeting: 'meeting',
};

export const currencyFormatter = (number) => {
  return '$' + parseFloat(number || 0).toLocaleString();
};

export const percentageFormatter = (number) => {
  return parseInt(number) + '%';
};

export const phoneNumberFormatter = (number, ext, op = '.') => {
  // return number ? (ext ? number + ` Ext: ${ext}` : number) : '';
  return (
    (number ? number?.substr(0, 3) + op + number?.substr(3, 3) + op + number?.substr(6, 4) : '') +
    (number && ext ? ` Ext: ${ext}` : '')
  );
};

export const getCleanPhoneNumber = (number) => {
  return number?.replace(/[^\w\s]/gi, '')?.replace(/\s/g, '');
};

export const actionDate = (date) => {
  const today = moment().startOf('day');
  const tomorrow = moment().startOf('day').add(1, 'days');
  const yesterday = moment().startOf('day').subtract(1, 'days');
  const eDate = moment(date);

  const dayDiff = moment(date).startOf('d').diff(today, 'd');
  const actiondate = dayDiff + ' Days';

  if (eDate?.isSame(today, 'day')) {
    return 'Today';
  } else if (eDate.isSame(tomorrow, 'day')) {
    return 'Tomorrow';
  } else if (eDate.isSame(yesterday, 'day')) {
    return 'Yesterday';
  } else if (moment(date).diff(moment(), 'd') >= 30) {
    return moment(date).format('MM/DD/YYYY');
  } else if (dayDiff < 0) {
    return Math.abs(dayDiff) + ' Days Ago';
  } else {
    return actiondate;
  }
};
export const actionDateClass = (date) => {
  const today = moment().startOf('day');
  const dayDiff = moment(date).startOf('d').diff(today, 'd');
  if (dayDiff < 0) return 'red';
  else return '';
};

export const getCharFromNumber = (number) => {
  if (number > 26) {
    const temp = number % 26;
    const temp2 = String.fromCharCode(64 + Math.floor(number / 26) - 1);
    return temp2 + String.fromCharCode(64 + temp);
  }
  return String.fromCharCode(64 + number);
};

export const joinDateAndTime = (date, time) => {
  if (date) {
    const formattedDate = moment(date);
    if (time) {
      formattedDate.set('hours', moment(time).hours());
      formattedDate.set('minutes', moment(time).minutes());
    }
    return formattedDate.toDate();
  } else return null;
};

export const formateDateOnly = (date) => {
  if (date) return moment(date).format('MM-DD-YYYY');
  else return '-';
};

export const formateTimeOnly = (time, type = 'time') => {
  if (time) {
    time = time.toString();
    if (time.length !== 4) {
      time =
        Array(4 - time.length)
          .fill(0)
          .join('') + time.toString();
    }
    if (type === 'time') return moment(time, 'HHmm').format('hh:mm A');
    else return moment(time, 'HHmm');
  } else if (type === 'time') return '-';
  else return null;
};

export const getAddress = (data) => {
  const parts = [];
  if (data.streetAddress) parts.push(data.streetAddress);
  if (data.city) parts.push(data.city);
  if (data.state) parts.push(data.state);
  if (data.zipCode) parts.push(data.zipCode);
  return parts.join(', ');
};

export const fullName = (data) => {
  return data.firstName + ' ' + data.lastName;
};

export const getPricing = () => {
  return { dealSize: '', commission: '', key: Math.random(), productId: null, productRowId: null };
};

export const getDayDiffToCurrent = (date) => {
  return moment().startOf('day').diff(moment(date).startOf('day'), 'days');
};

export const combineDateAndTime = (date, time) => {
  let dateTime = moment(date);

  time = time.toString().length === 3 ? '0' + time.toString() : time.toString();

  const hours = parseInt(time.slice(0, 2), 10);
  const minutes = parseInt(time.slice(2), 10);

  return dateTime.set({ hour: hours, minute: minutes, second: 0 });
};
